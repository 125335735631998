var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (this.$account.isFullAdmin || this.$account.permissions.canShowEmailQueue === true)?_c('div',{staticClass:"messages"},[_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.messagesList,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id.slice(-5))+" ")]}},{key:"item.subject",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.message.subject)+" ")]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.delivery.state)+" ")]}},{key:"item.endTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.delivery.endTime))+" ")]}},{key:"item.startTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.delivery.startTime))+" ")]}}],null,true)}),_c('div',{staticClass:"pagination-wrapper"},[_c('button',{staticClass:"pagination_btn pagination-btn--previous",attrs:{"disabled":_vm.currentPage === 1},on:{"click":function($event){return _vm.prev()}}},[_c('v-icon',{staticClass:"pagination_btn-icon mr-2"},[_vm._v("mdi-chevron-left")]),_vm._v("Previous ")],1),_c('p',{staticClass:"pagination_total-page"},[_vm._v("Page "+_vm._s(_vm.currentPage)+" of "+_vm._s(_vm.totalPages)+" ("+_vm._s(_vm.totalEmail)+" results)")]),_c('button',{staticClass:"pagination_btn pagination-btn--next",attrs:{"disabled":_vm.currentPage === _vm.totalPages},on:{"click":function($event){return _vm.next()}}},[_vm._v(" Next"),_c('v-icon',{staticClass:"pagination_btn-icon ml-2"},[_vm._v("mdi-chevron-right")])],1)])],1):_c('div',{staticClass:"messages"},[_c('v-sheet',{staticClass:"pa-4",attrs:{"color":"white"}},[_vm._v(" You are not allowed on this page... ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }